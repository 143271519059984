
.backgroundColor2 {
    --ion-background-color: #FFFFFF;
}
.hammericon {
    height: 50px;
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    color: black;
}

.allTaskButtons {
    
    float:left;
    width:50%;

}

.missionCategory {
    text-align: center;
}

.islandImg {
    width:100%;
    position:relative, fixed;
    
}

.parentImage {
}

.childBoat {
    position: absolute;
    position: absolute;
    top: 30%;
    left: 70%;
    height: 15%;
}

.childHouse {
    position:absolute;
    top:27%;
    left:15%;
    height:15%;
}

.houseImg {
    height:200px;
}

.titleIcons {
    width:40px;
    display: inline-block;
    color: black;
}

.filterModal {
    text-align: center;
}

#distanceInput:after {
    text-align: right;
    content: " mi."
}

#wageInput:after {
    text-align: right;
    content: "/hr"
}

ion-checkbox {
    margin: auto;
}

.centeredContent {
    left:9%;
}
.questionCard {
    width:300px;
}
.checkboxText {
    
    padding-left:14px;

}
.checkboxText2 {
    padding-left: 0px;
}
.labelText {
    color:black;
    font-size:18px;
}
.filterCheckbox {
    padding-bottom:-10px;
    bottom:-10px;

}
.blackText {
    color: black;
}
.checkboxPosition1 {
    position:relative;
    left:-15px;

}
.checkboxPosition2 {
    position: relative;
    left: -10px;
}
.checkboxPosition3 {
    position: relative;
    left: -25px;
}

.headerButton1 {
    position: relative;
    right:-60px;

}

.headerButton2 {
    position: relative;
    right: -30px;
}
.centeredText {
    text-align:right;
    }
.jobTitle {
    font-weight:bold;
}
.leftCol{
    float:left;
}
.headerButton3{
    position:relative;
    right:-90px;
}