.houseImg {
    height:200px;
}

.houseText {
    text-align:center;
    

}

.greenValue {
    color:limegreen;
    width:50%;
    text-align:left;
    float:left;
    padding-left: 10px;
    
}

.redValue {
    color: red;
    width: 50%;
    text-align: left;
    float:left;
    padding-left:10px;
}

.changeDesc {
    float:left;
    width:50%;
    color:black;
    text-align:right;
    
   
}

.linkText {
    float:right;
    color:black;
    font:italic;
    text-decoration:none;

}

.dotwName {
    float: left;
    width: 25%;
    color: black;
    text-align: center;
}

.eventDesc {
    color: black;
    width: 75%;
    text-align: left;
    float: left;
}

.currentPoints {
    padding-left:10%;
}

.greenValuePoints {
    color: limegreen;
    padding-left:10%;
}

.redValuePoints {
    color: red;
    padding-left: 10%;
}

.taskDesc {
    text-align:center;
}

.title {
    margin-top:0;
}

.warningText {
    text-align:center;
}