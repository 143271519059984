/* .leaflet-container {
    width: 100wh; 
    height: 100vh;
  } */

/* .gol-map .leaflet-control {
  border: none !important;
}

.gol-map .leaflet-control ion-button[shape=round] {
  --border-radius: 50%;
  --padding-end: 0;
  --padding-start: 0;
  width: 56px;
  height: 56px;
} */

.recenter-control {
  color: black;
  width: 30px;
  height: 30px;
  margin: 0;
  font-size: 10px;

  --background: white;
  --padding-start: 0;
  --padding-end: 0;
}