.key-item ion-label {
    word-wrap: normal !important;
    white-space: normal !important;
}

.key-item ion-note {
    margin-top: auto;
    margin-bottom: auto;
}

.key-description {
    font-size: 12px !important;
}

.key-sessions ion-list {
    width: 100%;
}