.middleRight {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.topRight {
    position: absolute;
    top: 5px;
    right: 5px;
}

.topLeft {
    position: absolute;
    top: 5px;
    left: 5px;
}

.rwat-last-person {
    opacity: 0.4;
}

p.topLeft {
    font-size: small;
    font-weight: lighter;
}

ion-grid.social {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

ion-range::part(pin) {
    left: 50%;
    padding: 8px 8px;
    transform: translate3d(-50%, 0px, 0px) scale(0.01);
}

ion-range.range-pressed::part(pin) {
    transform: translate3d(-50%, -24px, 0px) scale(1);
}