.rewardItem {
    width: 50% !important;
}

.rewardItem > ion-card {
    width: 100%;
}

.claimedRewardCard {
    background-color: #f2f2f2;
}

.stamp {
    width: 90px;
    transform: translate(-50%, -50%) rotate(-45deg);
    top: 50%;
    position: absolute;
    left: 50%;
}

.unearnedRewardCardColumn {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*Generated with https://ionicframework.com/docs/theming/colors#new-color-creator*/

:root {
  --ion-color-cash: #5f9ea0;
  --ion-color-cash-rgb: 95,158,160;
  --ion-color-cash-contrast: #000000;
  --ion-color-cash-contrast-rgb: 0,0,0;
  --ion-color-cash-shade: #548b8d;
  --ion-color-cash-tint: #6fa8aa;
  --ion-color-cart: #b0c4de;
  --ion-color-cart-rgb: 176,196,222;
  --ion-color-cart-contrast: #000000;
  --ion-color-cart-contrast-rgb: 0,0,0;
  --ion-color-cart-shade: #9bacc3;
  --ion-color-cart-tint: #b8cae1;
  --ion-color-shirt: #ffb6c1;
  --ion-color-shirt-rgb: 255,182,193;
  --ion-color-shirt-contrast: #000000;
  --ion-color-shirt-contrast-rgb: 0,0,0;
  --ion-color-shirt-shade: #e0a0aa;
  --ion-color-shirt-tint: #ffbdc7;
  --ion-color-car: #4169e1;
  --ion-color-car-rgb: 65,105,225;
  --ion-color-car-contrast: #ffffff;
  --ion-color-car-contrast-rgb: 255,255,255;
  --ion-color-car-shade: #395cc6;
  --ion-color-car-tint: #5478e4;
}

.ion-color-cash {
  --ion-color-base: var(--ion-color-cash);
  --ion-color-base-rgb: var(--ion-color-cash-rgb);
  --ion-color-contrast: var(--ion-color-cash-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cash-contrast-rgb);
  --ion-color-shade: var(--ion-color-cash-shade);
  --ion-color-tint: var(--ion-color-cash-tint);
}

.ion-color-cart {
  --ion-color-base: var(--ion-color-cart);
  --ion-color-base-rgb: var(--ion-color-cart-rgb);
  --ion-color-contrast: var(--ion-color-cart-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cart-contrast-rgb);
  --ion-color-shade: var(--ion-color-cart-shade);
  --ion-color-tint: var(--ion-color-cart-tint);
}

.ion-color-shirt {
  --ion-color-base: var(--ion-color-shirt);
  --ion-color-base-rgb: var(--ion-color-shirt-rgb);
  --ion-color-contrast: var(--ion-color-shirt-contrast);
  --ion-color-contrast-rgb: var(--ion-color-shirt-contrast-rgb);
  --ion-color-shade: var(--ion-color-shirt-shade);
  --ion-color-tint: var(--ion-color-shirt-tint);
}

.ion-color-car {
  --ion-color-base: var(--ion-color-car);
  --ion-color-base-rgb: var(--ion-color-car-rgb);
  --ion-color-contrast: var(--ion-color-car-contrast);
  --ion-color-contrast-rgb: var(--ion-color-car-contrast-rgb);
  --ion-color-shade: var(--ion-color-car-shade);
  --ion-color-tint: var(--ion-color-car-tint);
}
