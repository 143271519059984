.status-box {
    border-radius: 10px;
}

.due-time-box {
    border-radius: 10px;
}

.month-text {
    padding-left: 2%;
    padding-right: 2%;
}

.schedule-log h1 {
    white-space: nowrap;
    font-size: 24px;
}

.schedule-log p {
    white-space: normal;
    padding-left: 5%;
}