.home-pt-top {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
    color: black;
}

.home-pt-top h4 {
    font-size: x-large;
    font-weight: bold;
}

.home-pt-top p {
    font-size: large;
}

.pt-positive {
    color: limegreen;
}