ion-col {
    color: black;
}
.labelText {
    color: black;
    font-size: 18px;
    font-weight:bold;
    position:relative;
    left:-10px;
}

.blackText {
    color: black;

}
.centeredText {
    text-align: right;
}
.leftCol {
    float: left;
}

.heartButton {
    position: relative;
    right: -90px;
}
.deleteButton {
    position: relative;
    right: -60px;
}

.jobTitle {
    font-weight:bold;
}