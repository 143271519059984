.eventHeader {
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    border-bottom: 1px solid grey;
}

.eventApproval {
    display: flex;
    height: fit-content;
    align-self: center;
    align-items: center;
    color: white;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
}

.eventApprovalStatus {
    text-transform: capitalize;
    padding-right: 8px;
}

.eventApprovalIcon {
    position: relative;
    top: 2px;
    font-size: small;
    color: white;
}

.eventApprovalIcon .MuiSvgIcon-root {
    font-size: unset;
}

.eventView.eventapproved .eventApproval, .eventApproval.eventapproved {
    background-color: #00C853;
}

.eventView.eventpending .eventApproval, .eventView.eventoptional .eventApproval, .eventApproval.eventoptional, .eventApproval.eventpending {
    background-color: #2962FF;
}

.eventView.eventrejected .eventApproval, .eventView.eventrequired .eventApproval, .eventApproval.eventrejected, .eventApproval.eventrequired {
    background-color: #2000d5;
}

.eventView.eventmeeting .eventName > ion-icon {
    color: #1976D2;
}

.eventView.eventjob .eventName > ion-icon {
    color: #7E57C2;
}

.eventWeekday {
    font-weight: lighter;
}

.eventMonthDay {
    font-size: 20px;
    font-weight: bold;
}

.eventTitle {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    padding-top: 16px;
    align-items: center;
}

.eventName {
    display: flex;
    align-items: center;
}

.eventTime {
    display: flex;
    align-items: center;
}

.eventName > ion-icon {
    font-size: 32px;
}

.eventTime > ion-icon {
    font-size: 16px;
}

.eventName > span {
    padding-left: 12px;
    font-weight: bold;
}

.eventTime > span {
    font-size: 20px;
    padding-left: 4px;
}

.eventDescription {
    border-left: 2px solid gray;
    margin-left: 8px;
    padding-left: 24px;
}

.eventDescription > p {
    white-space: pre-wrap;
}

.eventView ion-list-header {
    font-weight: bold;
    font-size: 20px;
}

.eventView ion-list ion-label {
    padding-left: 8px;
}

.eventMeetings ion-icon {
    font-size: 32px;
}

.eventMeetings {
    color: black;
}

.eventMeetingName {
    color: black;
    font-weight: bold;
}

.eventMeetingLocation {
    text-align: right;
}

.alert-delete {
    color: red !important;
}

.eventMeta {
    font-size: small;
    margin-top: 8px;
}

.eventBadges { 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}