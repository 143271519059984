@keyframes at-ripple {
    0% {
      box-shadow: none;
    }
    50% {
      box-shadow: 0 4px 10px rgba(56, 128, 255, 0.15), 0 0 0 0 rgba(56, 128, 255, 0.15), 0 0 0 5px rgba(56, 128, 255, 0.15), 0 0 0 10px rgba(56, 128, 255, 0.15);
    }
    100% {
      box-shadow: 0 4px 10px rgba(56, 128, 255, 0), 0 0 0 5px rgba(56, 128, 255, 0), 0 0 0 10px rgba(56, 128, 255, 0), 0 0 0 15px rgba(56, 128, 255, 0);
    }
  }

.ttsContainer {
    flex: 0 1 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.ttsContent {
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.ttsPrevious, .ttsNext {
    display: flex;
    width: min-content;
}

.ttsButton {
    flex: 1 0 75%;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    align-items: center;
    overflow-y: scroll;
}

.questionBox {
    position: relative;
    display: inline-block;
    height: auto;
    background-color: lightblue;
    padding: 16px 16px;
    border-radius: 16px;
    width: 80%;
    margin: 8px auto;
}

.questionBox.response-bubble {
    background-color: lightgray;
}

.questionBox::after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -16px;
    right: auto;
    top: 0px;
    bottom: auto;
    border: 18px solid;
    border-color: lightblue transparent transparent transparent;
}

.questionBox.response-bubble::after {
    left: auto;
    right: -16px;
    border-color: lightgray transparent transparent transparent;
}

.questionBox.tts-reading {
    animation: at-ripple 1s linear infinite;
}

.questionText {
    max-height: 85%;
    width: 100%;
    /* overflow: scroll;
    overflow-y: auto;
    scrollbar-width: thin; */
}

.questionBox > ion-button {
    width:100%;
}

.responseForm {
    padding: 5% 5%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.ttsVoiceInput {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.ttsVoiceInput > ion-button {
    width: 100%;
}

.ttsTextInput {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    border: 1px solid slategray;
}

.ttsResponseBox {
    height: max-content;
    --padding-end: 8px;
}

.ttsResponseBox textarea {
    overflow-y: hidden;
    min-height: 32px;
}

.ttsFooter {
    display: flex;
    justify-content: space-between;
}

.ttsFooter > ion-button {
    width: 100%;
}

.fab-badge {
    position: absolute;
    top: 4px;
    right: 4px;
}

.info-button, .info-button::part(native) {
    overflow: visible;
}

.info-badge {
    position: absolute;
    font-size: 0.6em;
    top: 0px;
    right: -6px;
}

.session-info-item {
    background-color: lightblue;
    border-radius: 16px;
}

.ttsCombinedForm {
    display: flex;
    flex-flow: row nowrap;

    /* justify-content: space-between; */
    align-items: center;

    width: 100%;
    height: 100%;
}

.ttsInlineTextInput {
    flex: 1 0 auto;

    border: 1px solid slategray;
    border-radius: 16px;

    margin-right: 8px;

    height: 100%;
    overflow-y: scroll;
}

.ttsInlineButton {
    flex: 0 0 auto;
}

.ttsInlineButton > ion-button {
    position: relative;
    --border-radius: 50% !important;
    --padding-start: 0.5em;
    --padding-end: 0.5em;
    width: 56px;
    height: 56px;
}

ion-button.tts-recording {
    border-radius: 100%;
    animation: at-ripple 1s linear infinite;
}

.ttsChoiceForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.ttsChoices {
    position: relative;
    max-height: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
}

.ttsChoice {
    flex: 1 1 40%;
    max-width: 100%;
    min-width: 40%;
}

.questionText > p {
    margin: 0;
}