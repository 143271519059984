.calendar-view {
    padding-bottom: 16px;
}

.detail-view {
    overflow-y: scroll;
    border-top: 1px solid grey;
    height: 40%;
    margin-top: 8px;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar, .react-calendar__month-view > div > div {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar__viewContainer, .react-calendar__viewContainer > div, .react-calendar__viewContainer > div > div, .react-calendar__month-view__days {
    height: 100%;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__navigation {
    height: 44px;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__navigation span, .react-calendar__navigation button {
    font-size: 18px;
}

.react-calendar__tile {
    position: relative;
    font-weight: bold;
    font-size: 14px;
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
}

.calendarTileContainer {
    position: relative;
    left: 50%;
    top: -13px;
    width: 0;
    height: 0;
    z-index: 0;
}

.calendarActiveDay, .calendarToday, .calendarTodayActive {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.calendarActiveDay {
    border: 2px solid royalblue
}

.calendarToday {
    background-color: royalblue;
}

.calendarToday + .calendarActiveDay {
    width: 30px;
    height: 30px;
    border-width: 1px;
    border-color: white;
    overflow: visible;
}

.calendarEvents {
    position: absolute;
    bottom: -26px;
    width: 32px;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
}

.calendarEventMarker {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: royalblue;
    margin: 2px;
    flex-basis: auto;
}

.calendarEventMarker.eventRequired {
    width: 5px;
    height: 5px;
    margin: 1px;
    background-color: #2000d5 !important;
}

.calendarEventMarker.eventNotApproved {
    border: 2px double white;
}

.react-calendar__tile > abbr {
    position: relative;
    top: -6px;
    z-index: 100;
}

.react-calendar__month-view .react-calendar__tile--now {
    color: white;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }

  .react-calendar button {
    min-height: 65px;
  }

.calendarInstructions {
    padding: 2%;
    text-align: center;
    background-color: #a3d6db;
}

ion-item.ion-activated {
    --background-activated: white;
}