/* .task-card .reward-icon {
    position: absolute;

    top: 16px;
    right: 16px;
} */

.dueText {
    color:grey;
}
.taskTitleCompact {
    padding-top: 14%;
}

.iconColumn {
    padding-top: 14px;
}

p.rewardText {
    margin-top: 3%;
    text-align: right;
    color: grey;
}

p.taskDescription {
    text-align: left;
    padding: 10px;
}

.task-card {
    padding-top: 0px;
    padding-bottom: 10px;
    max-width: 220px;
}

#task-item.complete {
    background-color: #8080801a;
}
