.currentPoints {
    padding-left: 10%;
    font-size: 32px;
    float: left;
    font-weight: bold;
}

.pointsChange {
    float: right;
    padding-right: 10%;
    font-size: 28px;
    font-weight: bold;
}

.positive {
    color: limegreen;
}

.negative {
    color: red;
}

.taskOverview {
    text-align: center;
    margin-top: 16px;
    padding-top: 16px;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 32px;

    border-top: 1px solid black;
}

.taskOverview h1 {
    font-weight: bold;
    font-size: 28px;
}

.taskOverview .taskName {
    float: left;
}

.taskOverview .taskPoints {
    float: right;
}

.homeFiveKeyCategory {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}

.keyPoints {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    font-size: 32px;
}

.keyPoints ion-icon {
    font-size: 48px;
    padding-right: 8px;
}

.work ion-icon {
    --border-color: green;
    color: green;
}

.health ion-icon {
    --border-color: grey;
    color: grey;
}

.social ion-icon {
    --border-color: tan;
    color: tan;
}

.relationships ion-icon {
    --border-color: darkred;
    color: darkred;
}

.coping ion-icon {
    --border-color: darkblue;
    color: darkblue;
}

ion-title {
    font-weight:bold;
}

.taskAccord ion-card {
    width: 100%;
}

 #paneTwo {
    --offset-top: 0px;
}

.hijackButton > ion-icon {
    padding-right: 15%;
}

.hijackButton {
    width: 100%;
}

button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    min-height: 44px;
}

.swiper-wrapper {
    padding-bottom: 25px;
}

.vistaLoadingText {
    display: inline-block;
    margin-left: 44%;
    color: grey;
}

.unityVista {
    width: 100%;
    max-width: 957px;
    max-height: 479px;
    margin-left: auto;
    margin-right: auto;
    display: block;

    /*position:absolute;
    top:0;
    left:;
    z-index: 0;*/
}

@media (max-width: 950px) {
    .unityVista {
        width: 100%;
        height: 50vw;
    }
}