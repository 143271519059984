.reward-icon {
    font-weight: bold;
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.reward-icon.hammer {
    color: orange;
}

.reward-icon.gear {
    color: #a7000099;
}

.reward-icon.leaf {
    color: green;
}

.reward-icon.lightbulb {
    color: red;
}

.reward-icon.COIN {
    color: green;
}

.reward-icon.water {
    color: #3e3eff;
}

.reward-icon ion-icon, .reward-icon ion-text {
    display: inline-block;
    vertical-align: middle;
}