.radio ion-label, .radio .answerLabels {
    font-weight: lighter;
    font-style: italic;

    white-space: pre!important;
    overflow: visible!important;

    text-overflow: unset!important;
}

.radio ion-radio {
    width: 30px;
    height: 30px;
}