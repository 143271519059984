

.hammericon {
    height: 50px;
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    color: black;
}

.leftcol {
    width: 33%;
    float: left;
    padding-left: 10%;
}

.midcol {
    width: 33%;
    float: left;
    padding-left: 10%;
}

.rightcol {
    width: 34%;
    float: left;
    padding-left: 5%;
}

.missionCategory {
    text-align: center;
}

.slide1 {
    width: 90%;
}

.houseImg {
    height: 200px;
}

.scoreAmount {
    text-align: center;
}

.buttonLeftCol {
    width: 75%;
    float: left;
    white-space: normal;
}

.buttonRightCol {
    width: 30%;
    float: right;
}

.iconPicWork {
    font-size: 30px;
    color: green;
    padding-right: 0px;
}

.iconPicHealth {
    font-size: 30px;
    color: blue;
    padding-right: 0px;
}
