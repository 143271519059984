.home-task-top-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 100%;
}

.home-task-top {
    text-align: center;
}

.home-task-top h4 {
    font-size: large;
    font-weight: bold;
}

.home-task-top p {
    font-size: x-large;
}

.vertical-divider {
    border-right: 1px solid gray;
    min-height: 100%;
}

.home-task-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
}

.home-task-task {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
}

.home-task-task:last-child {
    margin-bottom: 0;
}

.home-task-task p {
    flex-grow: 1;
    font-size: large;
}

.home-task-reward {
    display: flex;
    align-items: center;
    color: green;
    flex-shrink: 0;
    flex-basis: 30px;
}

.home-task-notasks {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-size: large;
    color: darkgray;
}