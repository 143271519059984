.assessment-description {
    font-size: 12px !important;
    word-wrap: normal;
    white-space: normal;
}

.assessment-title {
    word-wrap: normal;
    white-space: normal;
}

.assessment-item ion-note {
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
}