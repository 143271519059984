.logo {
    margin-top: 16px;
    margin-bottom: 16px;
}
.appTitle {
    height: 110px;
    

}

.loginContent {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}

.helpText {
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
}

.backgroundColor {
    --ion-background-color: linear-gradient(to bottom, #e5f7f8, #FFFFFF);
}

.loginButton {
    position: absolute;
    bottom: 15%;
    left: 30%;
    width: 40%;
}

ion-modal {
    --ion-background-color: #e5f7f8;
}

.verticalCenter {
    top: 50%;
}
