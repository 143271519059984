.reward-icon {
    font-weight: bold;
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.reward-icon.hammer {
    color: orange;
}

.reward-icon.gear {
    color: blue;
}

.reward-icon.leaf {
    color: green;
}

.reward-icon.lightbulb {
    color: red;
}

.reward-icon.COIN {
    color: green;
}

.reward-icon ion-icon, .reward-icon ion-text {
    display: inline-block;
    vertical-align: middle;
}

.reward-icon.hammer {
    color: #e99f18d9;
}

.reward-icon.leaf {
    color: #1b661b;
}

.reward-icon.heart {
    color: #ff8197;
}
.reward-icon.stop {
    color: #c72c2c;
}