
.column {
    float: left;
    width: 50%;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.iconPic {
    font-size:30px;
    color: green;
    padding-right:10px;
}
.slide1tab4 {
    width: 90%;
}
.categoryButtons {
   padding-left:5%;
   padding-right:5%;
}