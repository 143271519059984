ion-progress-bar {
    margin-bottom: 10px;
}

.timeCardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

/* .timeCardRight {
    width: 47.5% !important;
    float: right;
}

.timeCardLeft {
    width: 47.5% !important;
    float: left;
} */

.timeIcon {
    margin-left: 50%;
    transform: translateX(-50%);
}

.incentiveText {
    color: purple;
}


.errandText {
    color: orange;
}

.pendingRewardsSlide {
    width: 100%;
}

.claimedRewardsSlide {
    width: 100%;
}
