.calendarEventName {
    font-weight: bold;
}

.calendarEventSub {
    color: gray;
    font-weight: lighter;
    font-size: small;
}

.calendarEvent.eventrejected, .calendarEvent.eventunapproved {
    opacity: 0.4;
}

.calendarEvent.eventrejected .calendarEventName {
    text-decoration: line-through;
}

.calendarEvent.eventapproved .calendarEventApprovalIcon {
    color: #00C853;
}

.calendarEvent.eventpending .calendarEventApprovalIcon {
    color: #2962FF;
}

.calendarEvent.eventrejected .calendarEventApprovalIcon, .eventRequiredFlag {
    color: #2000d5;
}

.calendarEvent.eventmeeting .calendarEventTypeIcon {
    color: #1976D2;
}

.calendarEvent.eventjob .calendarEventTypeIcon {
    color: #7E57C2;
}